<template>
  <div class="comentarios-list">
    <b-row>
      <b-col cols="12" xl="12">
        <app-filtro
          :fechaDesde="fechaDesdeParam"
          :fechaHasta="fechaHastaParam"
          @filtrar="filtrar">
        </app-filtro>  
      </b-col>      
    </b-row>

    <b-row>
      <b-col cols="12" xl="12">  
        <transition name="slide">
        <b-card header="Listado de Comentarios" >
          <b-table hover
              responsive="sm" 
              class="registro"
              :busy="tableBusy"
              :items="comentarios" 
              :fields="itemFields">
              <!-- @row-clicked="consultar"> -->
            <div slot="table-busy" class="text-center">
              <b-spinner class="table-spinner" label="Spinning" />
            </div>              
            <template slot="fechaRegistro" slot-scope="data">
              {{ data.item.fechaRegistro | fechaHora }}
            </template>
            <template slot="fechaLeido" slot-scope="data">
              {{ data.item.fechaLeido | fechaHora }}
            </template>
            <!-- <template slot="propiedad" slot-scope="data">
              {{ data.item.propiedad ? data.item.propiedad.direccion : '' }}
            </template> -->

            <template slot="opciones" slot-scope="data">
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template slot="button-content">
                  <i class="fa fa-ellipsis-v"></i>
                </template>
                <b-dropdown-item 
                    :disabled="mensajeLeido(data.item.fechaLeido)"
                    @click.stop="marcarLeido(data.item)">
                  <i class="fa fa-check"></i>Marcar como Leído</b-dropdown-item>
              </b-dropdown>
            </template>

            <template slot="detailShowing" slot-scope="row">
              <b-btn class="detailShowing" 
                  size="sm" 
                  v-if="row.item.mensaje != ''" 
                  @click.stop="row.toggleDetails">
                {{ ( row.detailsShowing) ? '-' : '+' }}
              </b-btn>            
            </template>

            <template slot="row-details" slot-scope="row">
              <b-row>
                <b-col cols="12">
                  <p class="mensaje">{{ row.item.mensaje }}</p>
                </b-col>
              </b-row>
            </template>
          </b-table>
          <nav>
            <b-pagination size="sm" 
              :total-rows="totalRegistros" 
              :per-page="registrosPorPagina" 
              v-model="paginaActual" prev-text="Prev." next-text="Prox."/>
          </nav>
        </b-card>
        </transition>
      </b-col>
    </b-row>

    <app-modal-msj
      :visible="modalMsjVisible"
      :mensaje="modalMensaje"
      :variant="modalVariant"
      @okAction="okAction"
      @hiddenAction="hiddenAction">
    </app-modal-msj>

    <app-modal-confirm
      :visible="confirmModalVisible"
      :mensaje="confirmMsj"
      :okTitle="confirmOkTitle"
      :cancelTitle="confirmCancelTitle"
      @confirmOk="confirmOk"
      @confirmCancel="confirmCancel">
    </app-modal-confirm>
  </div>
</template>

<script>
import Filtro from  './listFiltro.vue';
import moment from 'moment';

import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "comentarios/getField",
  mutationType: "comentarios/updateField",
});


export default {
  name: 'ComentariosList',
  components: {
    appFiltro: Filtro,
  },
  data () {
    return {
      itemFields: [
        {
          key: 'detailShowing',
          label: '',
          class: 'text-center'
        },
        { 
          key: 'fechaRegistro',
          label: 'Fecha'
        },
        { key: 'nombre' },
        { key: 'email' },
        { key: 'telefono' },
        // { key: 'mensaje' },
        { key: 'propiedad' },
        { key: 'fechaLeido' },
        { 
          key: 'opciones',
          label: '',
          class: 'text-center'
        },
      ],

      tableBusy: false
    }
  },
  computed: {
    ...mapFields([
      'comentarios',
      'paginaActual',
      'registrosPorPagina',
      'totalRegistros',
      'totalPaginas',
      'fechaDesde',
      'fechaHasta'
    ]),
    fechaDesdeParam() {
      return moment(this.fechaDesde).format('YYYY-MM-DD');
    },
    fechaHastaParam() {
      return moment(this.fechaHasta).format('YYYY-MM-DD');
    }
  },
  created() {
    this.getItems();
  },
  methods: {
    filtrar(filtro) {
      this.fechaDesde = filtro.fechaDesde;
      this.fechaHasta = filtro.fechaHasta;
      this.paginaActual = 1;
      this.getItems();
    },
    getItems() {
      this.tableBusy = true;
      this.$store.dispatch('comentarios/getComentarios')
        .then(() => this.tableBusy = false)
        .catch(error => this.showErrorMsj(error));
    },
    mensajeLeido(fechaLeido) {
      return moment.utc(fechaLeido).format('YYYY-MM-DD') == '1900-01-01' ? false : true;
    },
    marcarLeido(item) {
      this.showModalConfirm('¿Desea marcar el mensaje como leído?', 
            this.marcarLeidoMensaje.bind(this, item.id), 'Si', null, 'No')
    },
    marcarLeidoMensaje(mensajeId) {
      this.showSpinner();
      this.$store.dispatch('comentarios/marcarLeido', mensajeId)
        .then(() => this.getItems())
        .catch(error => this.showErrorMsj(error))
        .finally(() => this.hideSpinner());
    }
  },
  watch: {
    'paginaActual' : function(newVal, oldVal) {
      if ( newVal != 0 && oldVal != 0) {
        this.getItems() 
      }
    },    
  }    

}
</script>

<style>
  .comentarios-list .registro table td {
    vertical-align: middle;
  }
  .comentarios-list .registro table td[aria-colindex="1"] {
    width: 30px;
  }
  .comentarios-list .registro table td[aria-colindex="2"] {
    width: 120px;
  }
  .comentarios-list .registro table td[aria-colindex="3"] {
    width: 15%;
  }
  .comentarios-list .registro table td[aria-colindex="4"] {
    width: 15%;
  }
  .comentarios-list .registro table td[aria-colindex="5"] {
    width: 15%;
  }
  .comentarios-list .registro table td[aria-colindex="6"] {
    width: calc(55% - 300px);
  }
  .comentarios-list .registro table td[aria-colindex="7"] {
    width: 120px;
  }
  .comentarios-list .registro table td[aria-colindex="8"] {
    width: 30px;
  }
  .comentarios-list .registro table p.mensaje {
    margin-bottom: 5px;
    margin-top: 0;
    padding-left: 45px;
    width: 60%;
    white-space: pre-wrap;
  }
</style>